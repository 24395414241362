import styled from "styled-components";
import {Link} from "gatsby";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: ${ props => props.long ? 'column' : 'row' };
    flex-wrap: wrap;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1250px;
    @media (max-width: 1160px) {
        width: 100%;
      }
    @media (max-width: 769px) {
      flex-direction: column;
      justify-content: center;
    }
`;

const Bar = styled.h1`
    text-align: center;
    background-color: #202620;
    color: #fff;
    padding: 25px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
  margin: 10px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin: 10px;
  margin-left: 0px;
  @media (max-width: 769px) {
    font-size: 20px;
  }
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin: 10px;
  margin-left: 0px;
  @media (max-width: 769px) {
    font-size: 16px;
  }
`;

const Para = styled.div`
  font-size: 16px;
  margin: 10px;
  margin-left: 0px;
`;

const LinkBtn = styled(Link)`
  color: rgb(30, 115, 190);
  :hover {
    color: #000;
    cursor: pointer;
`;

const Item = styled.li`
    padding-left: 20px;
    padding-top: 10px;
    font-size: 16px;
`;

export {
    Container,
    Bar,
    Info,
    Title,
    SubTitle,
    Para,
    LinkBtn,
    Item,
    Wrapper,
}