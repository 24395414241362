import styled from "styled-components";
import { Link } from "gatsby"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    algin-items: center;
    height: fit-content;
    min-width: 28%;
    background-color: #323A32;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    @media (max-width: 769px) {
      justify-self: center;
      width: 100%;
    }
`;

const Title = styled.h3`
  color: #F2C738;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Menu = styled.ul`
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0px;
  padding: 10px;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin-bottom: 10px;
  a:-webkit-any-link {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
`;

const MenuItem = styled.li`
  margin: 0px;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  :hover{
    color: #F2C738;
  }
`;

const Stripes = styled.img`
  height: 30px;
`;

const Number = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  background-color: #202620;
  text-align: center;
  padding: 35px;
`;

const Label = styled.div`
  margin: 0px;
  margin-left: 12px;
  font-size: 13px;
  font-weight: 600;
  color: grey;
  margin-bottom: -5px;
`;

const Break = styled.hr`
  background-color: #E2AE3B;
  height: 2px;
  margin: 10px;
  margin-top: 0;
  margin-bottom: 0;
`;

const Quote = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const QuoteBtn = styled.div`
  width: 80%;
  margin: 10px;
  padding: 20px;
  color: #fff;
  background-color: #000;
  font-weight: bold;
  margin-bottom: 50px;
`;

const InputForm = styled.input`
  padding: 10px;
  margin: 10px;
  width: 90%;
  color: #fff;
  background: transparent;
  border: solid #fff;
  border-width: thin;
  ::placeholder{
    color: #fff;
    font-weight: bold;
    opacity: 1;
  }
`;

const SubTitle = styled.div`
  color: #fff;
  font-size: 26px;
  font-weight: bold;
  margin: 5px;
`;

const GotoMenu = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

export {
    Container,
    Menu,
    MenuItem,
    Title,
    Stripes,
    Number,
    Break,
    Label,
    Quote,
    QuoteBtn, 
    InputForm, 
    SubTitle,
    GotoMenu,
}