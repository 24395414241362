import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Bar, Info, Title, SubTitle, Para, LinkBtn, Wrapper} from "../styles/servicesStyles"
import SideMenu from "../components/sideMenu"
import {ROUTES} from "../routes";

const About = () => (
  <Layout>
    <SEO 
    title="About" 
    discription="SR Affordable Equipment is a premier national heavy equipment brokerage firm and dealer. We have been buying and selling heavy equipment in the construction industry for over nine years and have developed many relationships with a multitude of different dealers, buyers, and other construction related resources."
    keywords={["SR Affordable Equipment", "SR Equipment", "Contact Us", process.env.GATSBY_PHONE_NUMBER, ]}
    />
    <Bar>ABOUT SR EQUIPMENT</Bar>
    <Container>
      <Wrapper>
        <Info>
            <Title>About SR Equipment, LLC.</Title>
            <SubTitle>Your Trusted Partner for Used Heavy Equipment Solutions.</SubTitle>
            <Para>SR Equipment, LLC is a leading national heavy equipment brokerage firm and dealer with a strong commitment to providing the finest buying and selling experience in the construction industry. With over nine years of expertise, we have cultivated relationships with a wide array of dealers, buyers, and other construction-related resources.</Para>
            
            <SubTitle>Our Unique Edge</SubTitle>
            <Para>What sets SR Equipment apart is our deep-rooted experience in the construction industry. We recognize the importance of cash flow and the need to generate capital swiftly and effectively in today's dynamic economy. As a result, we offer construction equipment at fair market value, distinguishing ourselves from the competition. Our high sales volume and assertive marketing strategies have propelled us ahead of our rivals.</Para>
            
            <SubTitle>A Story of Remarkable Growth</SubTitle>
            <Para>Over the years, SR Equipment has experienced tremendous growth, expanding from a small client base to serving hundreds of clients worldwide who continuously list machines with us. Our company was born out of the frustration of trying to buy and sell heavy equipment in the open market.</Para>

            <SubTitle>Introducing SR Equipment – Used Equipment Sales</SubTitle>
            <Para>We established SR Equipment, LLC to offer an all-encompassing buying and selling solution that streamlines the entire process for both buyers and sellers. Our primary objective is to provide exceptional customer service and become an invaluable asset to our clients' businesses. Understanding the value of your time, we are dedicated to saving both time and money for our clients.</Para>
            <Para>Driven by a team of young, energetic, and passionate professionals, we focus on forging lasting relationships with clients of all sizes. Whether you're local or nationwide, you can rely on us to deliver the most professional and highest-quality construction equipment sales service in the industry. SR Equipment is the ultimate destination for used construction equipment sales!</Para>
            <Para>If you're interested in buying or selling any used piece of equipment, don't hesitate to contact us today: <LinkBtn to={ROUTES.CONTACT}>Contact Us.</LinkBtn></Para>
        </Info>
        <SideMenu/>
      </Wrapper>
    </Container>
  </Layout>
)



export default About
